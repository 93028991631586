
import { Component } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { Permission } from '@/enums/Permission';

@Component({
  components: { VFormBuilder },
})
export default class TagForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      { name: 'name', type: InputType.Language, label: 'tag.form.name', rules: 'required' },
      { name: 'identifier', type: InputType.Text, label: 'tag.form.identifier', rules: 'required' },
      { name: 'sortOrder', type: InputType.Text, label: 'tag.form.sort', rules: 'integer', default: 1 },
      {
        name: 'isRecommendationTag',
        type: InputType.Checkbox,
        label: 'tag.form.recommendationTag',
        visible: this.canToggleRecommendationTag,
      },
    ];
  }

  public getData() {
    return { ...this.$refs.form.form };
  }

  public canToggleRecommendationTag() {
    return this.$can(Permission.ADMIN);
  }
}
